<template>
  <div>

    <div style="margin: 5px 5px">
      <a-button type="primary" @click="addClassEditor.show=true">添加</a-button>
    </div>

    <a-card
        style="width:100%"
        title="物品排序"
        :tab-list="itemViewer==1 ? bigList : smallList "
        @tabChange="key => onTabChange(key, 'key')"
        :active-tab-key="key"
    >
      <span slot="customRender" slot-scope="item"> <a-icon type="home"/>{{ item.key }} </span>
      <div slot="extra">

      </div>
      <div>

        <a-card-grid style="width:25%;text-align:center" v-for="(item,i) in itemCache" :key="i"
                     @click.right="deleteItem(item)">
          {{ item }}
        </a-card-grid>

        <a-popover v-model="editorClass.addtor" title="添加物品" trigger="click">
          <template slot="content">
            <div style="margin: 5px 0 ">
              <a-input addon-before="类名:" placeholder="回车添加" v-model="addtor" @keydown.enter="itemAddtor"/>
            </div>
          </template>
          <a-card-grid style="width:25%;text-align:center" v-if="key !=null">
            新增物品
          </a-card-grid>
        </a-popover>

        <a-card-grid style="width:25%;text-align:center" @click="openCommitEditor" v-if="key !=null">
          修改类别
        </a-card-grid>


      </div>

      <!--      <div v-if="itemShow.isgun == 1">-->

      <!--        <div>-->

      <!--          <div v-for="(item,i) in itemCache" :key="i"-->
      <!--               style="display: flex;flex-wrap: wrap;justify-content: space-around;align-content: center">-->


      <!--            <a-card-grid v-for="(items,index) in item" :key="index" style="width:45%;margin: 5px">-->
      <!--              &lt;!&ndash;              {{ items }}&ndash;&gt;-->

      <!--              <a-card-grid style="color: red;text-align: center">-->
      <!--                {{ index }}-->
      <!--              </a-card-grid>-->

      <!--              <a-card-grid v-for="(itemss,indexx) in items" :key="indexx"-->
      <!--                           style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center"-->
      <!--                           @click.right="deleteGunItem(index,indexx);">-->
      <!--                {{ itemss }}-->
      <!--              </a-card-grid>-->

      <!--              <a-popover v-model="openCommitGunEditor.addtor[index]" title="添加物品" trigger="click">-->
      <!--                <template slot="content">-->
      <!--                  <div style="margin: 5px 0 ">-->
      <!--                    <a-input addon-before="类名:" placeholder="回车添加" v-model="addtor"-->
      <!--                             @keydown.enter="addGunItem(index)"/>-->
      <!--                  </div>-->
      <!--                </template>-->

      <!--                <a-card-grid style="color: green;text-align: center">-->
      <!--                  新增物品-->
      <!--                </a-card-grid>-->
      <!--              </a-popover>-->


      <!--            </a-card-grid>-->


      <!--            <div style="width: 90%;margin: 10px 5px">-->
      <!--              <a-button type="primary" @click="openCommitEditor">修改类</a-button>-->

      <!--              <a-popover v-model="openCommitGunEditor.show" title="添加物品" trigger="click">-->
      <!--                <template slot="content">-->
      <!--                  <div style="margin: 5px 0 ">-->
      <!--                    <a-input addon-before="类名:" placeholder="回车添加" v-model="addtor"-->
      <!--                             @keydown.enter="addGunguns()"/>-->
      <!--                  </div>-->
      <!--                </template>-->

      <!--                <a-button style="margin: 0 10px" @click="addGunguns">添加子类</a-button>-->

      <!--              </a-popover>-->
      <!--            </div>-->

      <!--          </div>-->


      <!--        </div>-->

      <!--      </div>-->

    </a-card>


    <a-modal
        title="修改类名"
        :visible="editorClass.show"
        @ok="classCommit"
        @cancel="classCancel"
        okText="确认修改"
        cancelText="取消修改"
    >

      <div style="margin: 16px 0 ">
        <a-input addon-before="类名:" v-model="itemShow.tab"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="权重:" v-model="itemShow.index"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="隐藏:" v-model="itemShow.hide"/>
      </div>

      <div style="margin-bottom: 16px">
        <a-button type="danger" style="width: 100%" @click="deleteClass">删除此类</a-button>
      </div>
    </a-modal>


    <a-modal
        title="添加子类"
        :visible="addClassEditor.show"
        @ok="addCommit"
        @cancel="addCancel"
        okText="确认添加"
        cancelText="取消添加"
    >

      <div style="margin: 16px 0 ">
        <a-input addon-before="类名:" v-model="addClassEditor.cache.sorting_name"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="权重:" v-model="addClassEditor.cache.index"/>
      </div>
      <div style="margin-bottom: 16px">
        <a-input addon-before="隐藏:" v-model="addClassEditor.cache.hide"/>
      </div>

      <div style="margin-bottom: 16px">
      </div>
    </a-modal>


  </div>
</template>

<script>
const key = '123'
export default {
  name: "Guns",
  data() {
    return {
      editorClass: {
        show: false,
        cache: {},
        addtor: false,
      },
      openCommitGunEditor: {
        show: false,
        cache: {},
        addtor: [],
      },

      addClassEditor: {
        show: false,
        cache: {
          sorting_name: '',
          index: '',
          items: '',
          hide:0
        },
        addtor: [],
      },

      addtor: '',
      key: null,

      bigList: [],
      smallList: [],

      itemShow: [],
      itemCache: [],

      itemLog: {},

      itemViewer: 1,
    }
  },
  methods: {
    addCommit() {
      this.addClassEditor.cache.items = {[this.addClassEditor.cache.sorting_name]: []}
      this.$axios.post('/Admin/Api/SuitSorting/AddSorting', {
        ...this.addClassEditor.cache
      }).then(v => {
        this.$message.success(v.data.msg)
        setTimeout(() => {
          this.$router.go(0)
        }, 500)
      }).catch(e => this.$message.error(e.data.msg))

      this.addClassEditor.show = false
      this.addClassEditor.cache = {
        sorting_name: '',
        index: '',
        items: ''
      }
    },
    addCancel() {
      this.addClassEditor.show = false
      this.addClassEditor.cache = {
        sorting_name: '',
        index: '',
        items: ''
      }

    },
    openCommitEditor() {
      Object.assign(this.editorClass.cache, this.itemShow)
      this.editorClass.show = true
    },

    itemAddtor() {
      if (this.addtor.trim().length < 1) return
      this.itemCache.push(this.addtor)

      const keys = Object.keys(this.itemShow.items)[0]
      const values = this.itemCache

      const kv = {
        [keys]: values
      }

      this.itemLog.items = kv
      console.warn(this.itemLog)
      this.$axios.post(`/Admin/Api/SuitSorting/ChangeSorting/${this.itemShow.key}`, {
        ...this.itemLog
      }).then(
          value => {
            this.$message.success(value.data.msg)
          }
      ).catch(err => {
        this.$message.success(err.msg)
      })
      this.editorClass.addtor = false
      this.addtor = ''
    },

    classCommit() {
      Object.assign(this.itemLog, this.itemShow)
      this.itemLog.sorting_name = this.itemShow.tab
      this.itemLog.index = this.itemShow.index
      this.$axios.post(`/Admin/Api/SuitSorting/ChangeSorting/${this.itemShow.key}`, {
        ...this.itemLog
      }).then(
          value => {
            this.$message.success(value.data.msg)
            setTimeout(() => {
              this.$router.go(0)
            }, 500)
          }
      ).catch(err => {
        this.$message.success(err.msg)
      })
      this.editorClass.show = false

    },

    deleteClass() {
      const self = this
      // console.log(contentBody)
      this.$confirm({
        title: '是否删除该类',
        content: '此操作不可恢复，只能重新添加',
        okText: '确定删除',
        cancelText: '取消',
        onOk() {
          self.$axios.post(`/Admin/Api/SuitSorting/DelSorting/${self.itemShow.key}`).then(
              value => {
                self.$message.success(value.data.msg)
                setTimeout(() => {
                  self.$router.go(0)
                }, 500)
              }
          ).catch(err => {
            self.$message.success(err.msg)
          })
        },
        onCancel() {
        },
      });

    },


    classCancel() {
      Object.assign(this.itemShow, this.editorClass.cache)
      this.$message.warning({content: '取消修改', key, duration: 2})
      this.editorClass.show = false
    },

    onTabChange(key) {
      this.key = key
      this.itemShow = this.bigList.find(item => item.key == key)
      this.itemCache = Object.values(this.itemShow.items)[0]
      this.itemLog = {
        sorting_name: this.itemShow.sorting_name,
        items: this.itemShow.items,
        index: this.itemShow.index,
      }
    },

    deleteItem(item) {
      const self = this

      // console.log(contentBody)
      this.$confirm({
        title: '是否删除该物品',
        content: '此操作不可恢复，只能重新添加',
        okText: '确定删除',
        cancelText: '取消',

        onOk() {
          self.itemCache = self.itemCache.filter(val => val != item)
          const keys = Object.keys(self.itemShow.items)[0]
          const values = self.itemCache
          console.log(self.itemCache)

          const kv = {
            [keys]: values
          }


          self.itemLog.items = kv
          self.$axios.post(`/Admin/Api/SuitSorting/ChangeSorting/${self.itemShow.key}`, {
            ...self.itemLog
          }).then(
              value => {
                self.$message.success(value.data.msg)
              }
          ).catch(err => {
            self.$message.success(err.msg)
          })
        },
        onCancel() {
        },
      });
    },

    // deleteGunItem(key, index) {
    //
    //   console.log(key, index)
    //
    //   const self = this
    //   // console.log(contentBody)
    //   this.$confirm({
    //     title: '是否删除该物品',
    //     content: '此操作不可恢复，只能重新添加',
    //     okText: '确定删除',
    //     cancelText: '取消',
    //     onOk() {
    //       self.itemShow.items.guns[key].splice(index, 1)
    //
    //       self.itemLog.items = self.itemShow.items
    //       self.$axios.post(`/Admin/Api/ItemSorting/ChangeSorting/${self.itemShow.key}`, {
    //         ...self.itemLog
    //       }).then(
    //           value => {
    //             self.$message.success(value.data.msg)
    //           }
    //       ).catch(err => {
    //         self.$message.success(err.msg)
    //       })
    //     },
    //     onCancel() {
    //     },
    //   });
    //
    // },

    addClass() {
      if (this.addtor.trim().length < 1) return
      console.log(key)
      this.addtor = ''

    },

    // addGunguns() {
    //   console.log(this.itemShow.items.guns)
    // },
    //
    // addGunItem(key) {
    //   if (this.addtor.trim().length < 1) return
    //   console.log(key)
    //   this.$set(this.openCommitGunEditor.addtor, key, false)
    //   this.itemShow.items.guns[key].push(this.addtor)
    //   this.itemLog.items = this.itemShow.items
    //   this.$axios.post(`/Admin/Api/ItemSorting/ChangeSorting/${this.itemShow.key}`, {
    //     ...this.itemLog
    //   }).then(
    //       value => {
    //         this.$message.success(value.data.msg)
    //       }
    //   ).catch(err => {
    //     this.$message.success(err.msg)
    //   })
    //   this.addtor = ''
    // },

  },
  watch: {
    itemViewer: {
      // eslint-disable-next-line no-unused-vars
      handler(n) {
        this.key = null
        // if (this.itemViewer) {
        //   this.itemShow = this.bigList[0]
        // } else {
        //   if (this.smallList.length < 1) {
        //     this.$message.error('暂无数据')
        //     this.itemCache = []
        //     return
        //   }
        //   this.itemShow = this.smallList[0]
        // }
        // if (n) {
        //   this.key = this.bigList[0].key
        //   this.itemCache = Object.values(this.bigList[0].items)[0]
        // } else {
        //   if (this.smallList.length < 1) {
        //     this.$message.error('暂无数据')
        //     this.itemCache = []
        //     return
        //   }
        //   this.key = this.smallList[0].key
        //   this.itemCache = Object.values(this.smallList[0].items)[0]
        // }
        this.itemCache = []
      }
    }
  },
  async created() {

    const Decode = JSON.parse((await this.$axios.post('/Admin/Api/SuitSorting/GetSortingList')).data.data)
    Decode.forEach(item => {
      this.bigList.push({
        key: item.id,
        tab: item.sorting_name,
        ...item,
      })
    })

    // this.itemShow = this.bigList[0]
    // this.key = this.bigList[0].key
    // this.itemCache = Object.values(this.bigList[0].items)[0]

    window.oncontextmenu = function (e) {
      e.preventDefault();
    };
    console.log(this.smallList)
    console.log(Decode)
  }
}
</script>

<style scoped>

</style>
